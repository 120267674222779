

































































































































































































































































































import {
  computed,
  defineComponent,
  onMounted,
  provide,
  reactive,
  toRefs,
  watch
} from "@vue/composition-api";
import { getConsumptionDataList } from "@/api/energy-data/consumption_data";
import {
  deleteConsumerData,
  getConsumerData
} from "@/api/organizations/consumer";
import { dynamicSort, isDevMode, seperateThousand } from "@/utils";
import { formattedAddress } from "@/helpers/formats";
import { rootInstance } from "@/helpers/composition";

import { Notification } from "element-ui";
import { cloneDeep } from "lodash";

import { IConsumptionData } from "@/lib/types/energy-data/consumption_data";
import { IConsumerDetails } from "@/lib/types/organizations/consumer";
import { OrganizationTypes } from "@/lib/types/base";
import { getConsumptionSitesList } from "@/api/energy-assets/consumption_site";

import AnimatedNumber from "animated-number-vue";

export default defineComponent({
  name: "Consumer",
  components: {
    AggregatedChart: () => import("@/components/charts/AggregatedChart.vue"),
    ApexChart: () => import("@/components/charts/ApexChart.vue"),
    AggregatedDatalist: () =>
      import("@/components/datalists/AggregatedDatalist.vue"),
    PieChart: () => import("@/components/charts/PieChart.vue"),
    ConsumerDetails: () => import("@/components/consumer/ConsumerDetails.vue"),
    ConsumerUsers: () => import("@/components/consumer/ConsumerUsers.vue"),
    ConsumerCrudForm: () => import("@/components/forms/ConsumerCrudForm.vue"),
    ConsumptionSitesList: () =>
      import("@/components/consumption-site/ConsumptionSitesList.vue"),
    ConsumerCertificates: () =>
      import("@/components/consumer/ConsumerCertificates.vue"),
    AnimatedNumber,
  },
  setup() {
    const { root } = rootInstance();

    const roles = computed(() => root.$store.getters["user/roles"]);
    // const isDev = process.env.
    const isDev = isDevMode();

    const state = reactive({
      details: {} as IConsumerDetails,
      detailsInit: {} as IConsumerDetails,
      detailsLoaded: false,
      consumptionData: {} as Partial<IConsumptionData>,
      consumerDataLoaded: false,
      datalistVisible: false,
      editModalVisible: false,
      sitesCount: 0,
      activeTab: "overview",
      isRouterAlive: true,
      consumerCrudFormValue: false,
      chartName: "Stacked Area Chart",
    });

    const consumerDataFn = () => {
      state.detailsLoaded = false;
      state.consumerDataLoaded = false;

      getConsumerData(root.$route.params.id)
        .then(async res => {
          state.details = cloneDeep(res);
          state.detailsInit = Object.freeze(state.details);
          state.detailsLoaded = true;

          await getConsumptionDataList({
            consumer: res.id,
            consumptionStartAfter: root.$store.state.period[0],
            consumptionStartBefore: root.$store.state.period[1],
            limit: 24,
            offset: 0,
          })
            .then(res => {
              state.consumptionData = res;
              state.consumerDataLoaded = true;

              if (state.consumptionData.results) {
                state.consumptionData.results.sort(
                  dynamicSort("consumption_start")
                );
              }
            })
            .catch(err => {
              state.consumerDataLoaded = false;
              console.log(err.body);
            });
        })
        .catch(err => {
          state.detailsLoaded = false;
          if (err.statusCode === 404) root.$router.push({ name: "NotFound" });
        });
    };

    consumerDataFn();

    const closeEditModal = () => {
      state.editModalVisible = false;
      state.details = cloneDeep(state.detailsInit);
    };

    const deleteSite = () => {
      root.$confirm(
        /* Message */
        `
          Are you sure you want to delete <code class="bg-border text-dark-l2 rounded-sm px-1">${state.details.name}</code>?
          <br>
          This action can only be <span class="font-medium">reversed by a superadmin</span>.
        `,
        /* Title */
        root.$i18n.t("labels.warning") as string,
        {
          confirmButtonText: root.$i18n.t("labels.delete") as string,
          cancelButtonText: root.$i18n.t("labels.cancel") as string,
          type: "warning",
          center: true,
          showClose: false,
          dangerouslyUseHTMLString: true,

          beforeClose: async (action, instance, done) => {
            if (action === "confirm") {
              instance.confirmButtonLoading = true;

              await deleteConsumerData(root.$route.params.id)
                .then(() => {
                  instance.confirmButtonLoading = false;

                  Notification({
                    title: "Operation successful",
                    message: `${state.details.name} deleted successfully`,
                    type: "success",
                    position: "bottom-left",
                  });

                  root.$router.push({ name: "ConsumersList" });

                  done();
                })
                .catch(err => console.error(JSON.stringify(err.body)));
              /*  */
            } else {
              done();
            }
          },
        }
      );
    };

    const siteUpdated = () => {
      consumerDataFn();
      state.editModalVisible = false;
    };
    const chartChange = (e: any) => {
      state.chartName = e;
    };
    onMounted(() => {
      const initialHash = window.location.hash.slice(1); // remove "#"
      state.activeTab = initialHash || "overview";

      getConsumptionSitesList({
        limit: 0,
        offset: 0,
        consumer: root.$route.params.id,
      })
        .then(res => (state.sitesCount = res.count))
        .catch(err => console.error(err.body.errors.detail));

      watch(
        () => [root.$store.state.period, state.activeTab],
        (newVal, oldVal) => {
          root.$router
            .replace({
              query: Object.assign(
                {},
                {
                  start: root.$store.state.period[0].split("T")[0],
                  end: root.$store.state.period[1].split("T")[0],
                }
              ),
              hash: state.activeTab,
            })
            .catch(() => {});

          if (
            JSON.stringify(newVal[0]) !== JSON.stringify(oldVal && oldVal[0])
          ) {
            consumerDataFn();
          }
        },
        { deep: true, immediate: true }
      );
    });

    /**
     * ! Getters
     */
    const totalAmountData = computed(() => {
      return {
        total_amount_matched:
          (state.consumptionData.total_amount_matched_solar !== null &&
          state.consumptionData.total_amount_matched_solar !== undefined
            ? state.consumptionData.total_amount_matched_solar
            : 0) +
          (state.consumptionData.total_amount_matched_wind !== null &&
          state.consumptionData.total_amount_matched_wind !== undefined
            ? state.consumptionData.total_amount_matched_wind
            : 0) +
          (state.consumptionData.total_amount_matched_hydro !== null &&
          state.consumptionData.total_amount_matched_hydro !== undefined
            ? state.consumptionData.total_amount_matched_hydro
            : 0) +
          (state.consumptionData.total_amount_matched_marine !== null &&
          state.consumptionData.total_amount_matched_marine !== undefined
            ? state.consumptionData.total_amount_matched_marine
            : 0) +
          (state.consumptionData.total_amount_matched_thermal !== null &&
          state.consumptionData.total_amount_matched_thermal !== undefined
            ? state.consumptionData.total_amount_matched_thermal
            : 0) +
          (state.consumptionData.total_amount_matched_solid !== null &&
          state.consumptionData.total_amount_matched_solid !== undefined
            ? state.consumptionData.total_amount_matched_solid
            : 0) +
          (state.consumptionData.total_amount_matched_liquid !== null &&
          state.consumptionData.total_amount_matched_liquid !== undefined
            ? state.consumptionData.total_amount_matched_liquid
            : 0) +
          (state.consumptionData.total_amount_matched_gaseous !== null &&
          state.consumptionData.total_amount_matched_gaseous !== undefined
            ? state.consumptionData.total_amount_matched_gaseous
            : 0),
        total_amount_consumed: state.consumptionData.total_amount_consumed,
        total_amount_matched_solar:
          state.consumptionData.total_amount_matched_solar,
        total_amount_matched_wind:
          state.consumptionData.total_amount_matched_wind,
        total_amount_matched_hydro:
          state.consumptionData.total_amount_matched_hydro,
        total_amount_matched_marine:
          state.consumptionData.total_amount_matched_marine,
        total_amount_matched_thermal:
          state.consumptionData.total_amount_matched_thermal,
        total_amount_matched_solid:
          state.consumptionData.total_amount_matched_solid,
        total_amount_matched_liquid:
          state.consumptionData.total_amount_matched_liquid,
        total_amount_matched_gaseous:
          state.consumptionData.total_amount_matched_gaseous,
        total_production_sites_matched:
          state.consumptionData.total_production_sites_matched,
      };
    });

    const hourlyAggregatedData = computed(() =>
      state.consumptionData.hourly_aggregated_data?.sort(dynamicSort("hour"))
    );
    const allHourlyData = computed(() =>
      state.consumptionData.hourly_aggregated_data_for_each_day?.sort(
        dynamicSort("date")
      )
    );

    provide(
      "consumptionData",
      computed(() => state.consumptionData)
    );

    const changeF = (newVal: any) => {
      state.consumerCrudFormValue = newVal;
    };

    const closeDialog = () => {
      if (state.consumerCrudFormValue) {
        root.$confirm(
          /* Message */
          root.$i18n.t("messages.sure_to_close") as string,
          /* Title */
          root.$i18n.t("labels.warning") as string,
          {
            confirmButtonText: "OK",
            cancelButtonText: root.$i18n.t("labels.cancel") as string,
            type: "warning",
            center: true,
            showClose: false,
            dangerouslyUseHTMLString: true,

            beforeClose: async (action, instance, done) => {
              if (action === "confirm") {
                instance.confirmButtonLoading = true;
                state.isRouterAlive = false;
                root.$nextTick(() => {
                  state.isRouterAlive = true;
                });
                state.editModalVisible = false;
                instance.confirmButtonLoading = false;
                done();
                /*  */
              } else {
                done();
              }
            },
          }
        );
      } else {
        state.isRouterAlive = false;
        root.$nextTick(() => {
          state.isRouterAlive = true;
        });
        state.editModalVisible = false;
        state.consumerCrudFormValue = false;
      }
    };
    return {
      ...toRefs(state),
      roles,
      deleteSite,
      isDev,
      closeEditModal,
      siteUpdated,
      formattedAddress,
      totalAmountData,
      hourlyAggregatedData,
      allHourlyData,
      seperateThousand,
      OrganizationTypes,
      closeDialog,
      changeF,
      chartChange,
    };
  },
});
